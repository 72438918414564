<template>
  <div id="app" class="box">
    <div class="wiper" id="section1">
      <div class="tabbar flex flex-jus-between" data-aos="fade-left">
        <div class="tabicon">
          <img src="./assets//images/main.png" alt="">
          <div>
            出房通
            <p>ZUFANGDIANDI</p>
          </div>
        </div>
        <el-menu :default-active="activeIndex" background-color="#00000000" text-color="#333" active-text-color="#00B074"
          class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">产品功能</el-menu-item>
          <el-menu-item index="3">免费使用</el-menu-item>
          <el-menu-item index="4">套餐比价</el-menu-item>
          <el-menu-item index="5">独立部署</el-menu-item>
          <el-menu-item index="6">关于我们</el-menu-item>
        </el-menu>
      </div>
      <swiper :options="swiperOptions">
        <swiper-slide class="item">
          <img src="./assets//images/swiper_one.png" class="swiper_img" alt="">
          <div class="item_text animated-text" data-aos="fade-up">
            <h1>出房通</h1>
            <h3>助力于中介行业，快速创建独立的店铺品牌形象</h3>
            <p>支持微信小程序、抖音小程序、QQ小程序</p>
            <div class="use_btn flex-center" @click="dialogVisible = true">申请使用</div>
          </div>
        </swiper-slide>
        <swiper-slide class="item">
          <img src="./assets//images/swiper_three.png" class="swiper_img" alt="">
          <div class="item_text animated-text" data-aos="fade-up">
            <h1>全城房源共享</h1>
            <h3>扩大业务范围，可出房源量增加，减少客户的流失，提高客户成交率。</h3>
          </div>
        </swiper-slide>
        <swiper-slide class="item">
          <img src="./assets//images/swiper_two.png" class="swiper_img" alt="">
          <div class="item_text animated-text" data-aos="fade-up">
            <h1>全城经纪人出房</h1>
            <h3>扩大业务范围，可出房源量增加，减少客户的流失，提高客户成交率。</h3>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- <div
            class="swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button-next"
            slot="button-next"
          ></div> -->
      </swiper>

    </div>
    <!-- 功能 -->
    <div class="function" id="section2">
      <div class="function_content flex flex-column flex-ali-center">
        <p class="title">了解我们的出房通产品功能</p>
        <div class="content flex flex-wrap flex-jus-between" data-aos="fade-up">
          <div class="content_item flex flex-column flex-ali-center flex-jus-center" v-for="item in functions"
            :key="item.icon">
            <span class="iconfont fonts" :class="item.icon"></span>
            <p class="text">{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 亮点 -->
    <div class="lightspot" id="section3">
      <div class="lightspot_content flex flex-column flex-ali-center">
        <p class="title">我们的产品亮点特色</p>
        <div class="content flex flex-jus-between">
          <div class="content_item flex flex-column flex-ali-center flex-jus-center" data-aos="fade-left"
            v-for="item in lightspots" :key="item.text">
            <img class="spot_img" :src="item.img" alt="">
            <p class="spot_text">{{ item.text }}</p>
            <p class="spot_tip">{{ item.tip }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 免费使用 -->
    <div class="three" id="section4">
      <div class="free">
        <div class="title">如何免费使用出房通平台</div>
        <div class="flex flex-jus-between tip">
          <div class="left">出房通商家版提供了一种简单、方便且免费的方式，为你打造自己独立的店铺品牌形象。只需轻松3步，即可开始享受出房通商家版的功能</div>
          <div class="right flex-center" @click="dialogVisible = true">申请使用 <div class="jiantou flex-center">→</div>
          </div>
        </div>
        <div class="step flex flex-jus-between">
          <div class="step_item flex flex-column flex-ali-center flex-jus-center" data-aos="fade-left"
            v-for="item in steps" :key="item.title">
            <p class="fs32 title">{{ item.title }}</p>
            <p>{{ item.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 套餐 -->
    <div class="package flex flex-column flex-ali-center">
      <p class="title">高性价比包年套餐，支持0免费试用</p>
      <div class="package_content flex">
        <div class="package_item" v-for="item in packages" :key="item.url" data-aos="fade-up">
          <img :src="item.url" class="image-item" alt="">
        </div>
      </div>
    </div>
    <!-- 部署小程序 -->
    <div class="deploy flex" id="section5">
      <div class="left">
        <p class="title">私有化部署小程序系统？</p>
        <p class="introduce">独立域名、数据库；独立的品牌，可招商代理；无区域限制，发展空间大</p>
        <div class="deploy_btn flex-center" @click="showBox" data-aos="fade-left">咨询客服</div>
      </div>
      <div class="right" data-aos="fade-left">
        <img src="./assets//images/deploy.png" alt="">
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="about" id="section6">
      <p class="title">关于我们</p>
      <div class="aboutus flex flex-jus-between">
        <div class="aboutus_item">
          <p class="name">源子闻科技有限公司</p>
          <p class="content">是一家专注于专注于房屋租赁和经纪人房源管理业务的创新型企业，
            基于自主研发的移租、出房通、管理平台等产品来简化租赁流程和改善用户体验。
            通过整合线上线下资源和技术创新，为中小企业提供全方位的租房解决方案。
          </p>
        </div>
        <div class="aboutus_item">
          <p class="name">咨询热线</p>
          <p class="content" style="font-size: 20px; color: #00B074;">
            19938230870
          </p>
          <p style="font-size: 11px;margin-top: 20px;">地址：城市锦江区东大街下东大街段328号东方时代商场</p>
        </div>
        <div class="aboutus_item">
          <p class="name">关注出房通</p>
          <div class="content flex">
            <div class="attion flex flex-column flex-ali-center">
              <img src="./assets/images/offical.png" alt="">
              <p>公众号</p>
            </div>
            <div class="attion flex flex-column flex-ali-center">
              <img src="./assets/images/qrcode.png" alt="">
              <p>小程序</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footer class="footer">
      ©2023 源子闻科技有限公司 版权所有 蜀ICP备2021020388号-1 公网安备 51010702002940
    </footer>
    <!-- 在线咨询 -->
    <div v-if="boxVisible" class="online" :class="{ 'enlarged': boxEnlarged }">
      <div class="top flex flex-jus-around flex-ali-center">
        在线咨询
        <span class="iconfont icon-x" @click="closetel" style="cursor: pointer;"></span>
      </div>
      <div class="bottom">
        <span class="iconfont icon-dianhua"></span>咨询电话
        <p class="tel">19938230870</p>
      </div>
    </div>
    <!-- 回到顶部 -->
    <el-backtop style="color: #00B074">
      <div class="iconfont icon-zhiding">
      </div>
    </el-backtop>
    <!-- 弹窗 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <div class="dialog_content">
        <img class="dialog_img" src="./assets/images/qrcode.png" alt="">
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
export default {
  name: 'App',
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
          autoplay: false
        },
        loop: true,
        effect: "fade",
        cube: {
          slideShadows: false,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.94,
        },
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
          bulletActiveClass: 'bullet-active',
          // progressbarOpposite: true,
        },
      },
      activeIndex: '1',
      functions: [
        {
          icon: 'icon-icon',
          text: '发布房源'
        },
        {
          icon: 'icon-zhinengsousuo',
          text: '智能搜索'
        },
        {
          icon: 'icon-dituzhaofang',
          text: '地图找房'
        },
        {
          icon: 'icon-fangyuanguanli',
          text: '房源管理'
        },
        {
          icon: 'icon-renyuanguanli',
          text: '人员管理'
        },
        {
          icon: 'icon-tubiaozhizuomoban-',
          text: '店铺管理'
        },
        {
          icon: 'icon-xiaoxi',
          text: '在线聊天'
        },
        {
          icon: 'icon-huabaofenxiang',
          text: '生成房源画报'
        },
        {
          icon: 'icon-kehutongjichaxun',
          text: '公司客户'
        },
        {
          icon: 'icon-a-aj-pc-wangqianhetongchaxun1x',
          text: '网签合同'
        },
        {
          icon: 'icon-a-Property1Entrustedtorent',
          text: '房源委托'
        },
        {
          icon: 'icon-fangwuzulinzonghezhengzhi',
          text: '提前开租'
        },
      ],
      lightspots: [
        {
          img: require('./assets/images/spot_share.png'),
          text: '伙伴房源共享',
          tip: '伙伴互关、房源信息得到最大化曝光，全城经纪人快速出租空置时间短'
        },
        {
          img: require('./assets/images/spot_user.png'),
          text: '直接免费获客',
          tip: '店铺房源直接对用户展示、获客无需支付任何线索费或者展位费。'
        },
        {
          img: require('./assets/images/spot_house.png'),
          text: '高效的房源管理',
          tip: '轻松管理杂乱繁多的房源信息，一键搜索，快速更新房屋状态。'
        },
        {
          img: require('./assets/images/spot_serve.png'),
          text: '实用的增值服务',
          tip: '用户快捷委托出租在线签约、公司客户管理、在线收款码等增值服务。'
        },
      ],
      steps: [
        {
          title: '1.登录',
          text: '微信搜索【出房通】小程序'
        },
        {
          title: '2.免费开店',
          text: '在“我的-开通商家版”购买免费版套餐 仅需支付0元'
        },
        {
          title: '3.开始使用',
          text: '登录进入商家版、上架房源、管理自己的店铺'
        },
      ],
      packages: [
        {
          url: require('./assets/images/package_free.png')
        },
        {
          url: require('./assets/images/package_base.png')
        },
        {
          url: require('./assets/images/package_standard.png')
        },
        {
          url: require('./assets/images/package_major.png')
        },
        {
          url: require('./assets/images/package_personal.png')
        },
      ],
      dialogVisible: false,
      boxVisible: true,
      boxEnlarged: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    let that = this;
    // 这里的this.width和this.height就是main.js中全局挂载的两个变量
    this.pageSize(this.width, this.height);
    window.addEventListener("resize", function() {
      //这里传的参数是当前浏览器窗口的宽高
      that.pageSize(window.innerWidth, window.innerHeight);
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleSelect(index) {
      this.activeIndex = index;
      const el = document.getElementById(`section${index}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    // 关闭咨询电话
    closetel() {
      this.boxVisible = false
    },
    // 滚动切换激活的tab
    handleScroll() {
      const sections = ['section1', 'section2', 'section3', 'section4', 'section5', 'section6'];
      const windowHeight = window.innerHeight;
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      for (let i = 0; i < sections.length; i++) {
        const el = document.getElementById(sections[i]);
        if (el) {
          const rect = el.getBoundingClientRect();
          if (rect.top <= windowHeight * 0.5 && rect.bottom >= windowHeight * 0.5) {
            this.activeIndex = String(i + 1);
            break;
          }
        }
      }
    },
    showBox() {
      if(this.boxVisible==false){
        this.boxVisible=true
      }
      this.boxEnlarged = true;
      setTimeout(() => {
        this.boxEnlarged = false;
      }, 2000);
    },
    pageSize(w, h) {
      let appDiv = document.getElementById("app");
      if (w == window.screen.width) {
        if (!this.isFullscreenForNoScroll()) {
          // 浏览器最大化但是未全屏
          appDiv.style.maxWidth = w + "px";
          appDiv.style.maxHeight = h + "px";
          appDiv.style.minWidth = w + "px";
          appDiv.style.minHeight = h + "px";
        } else {
          // 浏览器最大化且全屏
          appDiv.style.maxWidth = window.screen.width + "px";
          appDiv.style.maxHeight = window.screen.height + "px";
          appDiv.style.minWidth = window.screen.width + "px";
          appDiv.style.minHeight = window.screen.height + "px";
        }
      } else {
        // 浏览器不是最大化
        appDiv.style.maxWidth = this.width + "px";
        appDiv.style.maxHeight = this.height + "px";
        appDiv.style.minWidth = this.width + "px";
        appDiv.style.minHeight = this.height + "px";
      }
    },
    isFullscreenForNoScroll() {
      let wholeScreenFlag = false;
      var explorer = window.navigator.userAgent.toLowerCase();
      if (explorer.indexOf("chrome") > 0) {
        //webkit
        if (window.innerHeight === window.screen.height) {
          wholeScreenFlag = true;
        } else {
          wholeScreenFlag = false;
        }
      } else {
        //IE 9+  fireFox
        if (window.outerHeight === window.screen.height) {
          wholeScreenFlag = true;
        } else {
          wholeScreenFlag = false;
        }
      }
      return wholeScreenFlag;
    }
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  box-sizing: border-box;

  .wiper {
    width: 100%;
    height: 840px;
    position: relative;
    box-sizing: border-box;
    .tabbar {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      height: 90px;
      display: flex;
      align-items: center;
      padding: 0 84px;
      box-sizing: border-box;
      overflow: hidden;
      width: 100%;
      .tabicon {
        display: flex;
        align-items: center;
      }
    }

    .item {
      position: relative;

      .item_text {
        position: absolute;
        top: 210px;
        left: 294px;
        width: 541px;
        color: #fff;

        h1 {
          font-size: 64px;
        }

        h3 {
          font-size: 32px;
          font-weight: 400 !important;
          line-height: 42px;
          margin-top: 20px;
        }

        p {
          margin-top: 12px;
          font-weight: 400 !important;
        }

        .use_btn {
          width: 136px;
          height: 48px;
          background-color: #00B074;
          margin-top: 30px;
          border-radius: 4px;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }

  .function {
    margin-top: 160px;
    padding: 0 226px;
    box-sizing: border-box;
    height: 604px;

    .function_content {
      height: 100%;
      width: 100%;

      .title {
        font-size: 36px;
        font-weight: 400;
      }

      .content {
        width: 100%;
        height: 476px;
        margin-top: 80px;
        border: 1px solid #F4F7F6;
        box-shadow: 0px 0px 4px 0px #00000014;
        background-color: #F9FCFB;
        padding: 40px;

        .content_item {
          width: 180px;
          height: 188px;
          margin-right: 6px;

          .fonts {
            font-size: 45px;
          }

          .text {
            font-size: 20px;
            margin-top: 12px;
          }
        }

        .content_item:nth-child(6) {
          margin-right: 0;
        }

        .content_item:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .lightspot {
    margin-top: 162px;
    height: 485px;
    padding: 0 270px;
    box-sizing: border-box;

    .lightspot_content {
      height: 100%;
      width: 100%;

      .title {
        font-size: 32px;
        color: #000;
      }

      .content {
        width: 100%;
        height: 360px;
        margin-top: 80px;

        .content_item {
          height: 100%;
          width: 300px;
          padding: 0 40px;
          margin-right: 60px;
          box-shadow: 0px 4px 40px 0px #C1C7C54D;
          z-index: 2;
          background-color: #fff;

          .spot_img {
            height: 62px;
          }

          .spot_text {
            font-size: 20px;
            margin-top: 30px;
          }

          .spot_tip {
            font-size: 14px;
            color: #333333B2;
            margin-top: 30px;
          }
        }

        .content_item:last-child {
          margin-right: 0;
        }

        .content_item:first-child {
          background-color: #00B074;
          box-shadow: 0px 4px 40px 0px #00B0744D;
          color: #fff !important;

          .spot_tip {
            font-size: 14px;
            color: #FFFFFFB2;
          }
        }
      }
    }
  }

  .three {
    height: 924px;
    width: 100%;
    background-color: #E4F5EF;
    position: absolute;
    top: 2070px;
    left: 0;
    padding: 0 207px;
    box-sizing: border-box;

    .free {
      width: 100%;
      margin-top: 381px;
      height: 383px;

      .title {
        font-size: 32px;
      }

      .tip {
        margin-top: 20px;

        .left {
          width: 560px;
        }

        .right {
          height: 52px;
          width: 166px;
          background-color: #00B074;
          color: #fff;
          box-shadow: 0px 4px 20px 0px #A8C5BA;
          border-radius: 4px;
          cursor: pointer;

          .jiantou {
            height: 26px;
            width: 26px;
            background: #FFFFFF33;
            margin-left: 20px;
          }
        }
      }

      .step {
        height: 189px;
        margin-top: 80px;

        .step_item {
          height: 100%;
          width: 420px;
          background-color: #fff;

          .title {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .package {
    height: 1205px;
    margin-top: 744px;

    .title {
      font-size: 32px;
      margin-top: 160px;
    }

    .package_content {
      width: 100%;
      height: 766px;
      margin-top: 80px;
      padding-left: 80px;
      box-sizing: border-box;
      .package_item {
        width: 280px;
        height: 100%;
        margin-right: 40px;
        .image-item{
          width: 280px;
          height: 766px;
        }
      }
    }
  }

  .deploy {
    height: 560px;
    background-color: #5CCDA6;
    box-sizing: border-box;

    .left {
      color: #fff;
      margin: 160px 47px 0 280px;

      .title {
        font-size: 48px;
      }

      .introduce {
        font-size: 20px;
      }

      .deploy_btn {
        width: 136px;
        height: 48px;
        background-color: #E8CE13;
        box-shadow: 0px 4px 40px 0px #2CB887;
        border-radius: 4px;
        margin-top: 60px;
        cursor: pointer;
      }
    }

    .right {
      width: 642px;
      height: 464px;
      margin-top: 79px;
    }
  }

  .about {
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 36px;
      color: #000;
      margin-top: 200px;
    }

    .aboutus {
      width: 1360px;
      height: 400px;
      // margin-left: 280px;
      margin-top: 32px;
      padding: 60px 100px;
      box-sizing: border-box;

      .aboutus_item {
        width: 280px;
        height: 212px;

        .name {
          font-size: 20px;
          color: #000;
        }

        .content {
          font-size: 14px;
          color: #666;
          margin-top: 60px;

          .attion {
            margin-right: 40px;
          }
        }
      }
    }
  }

  .footer {
    height: 60px;
    padding-left: 280px;
    box-sizing: border-box;
    color: #061F1699;
    font-size: 14px;
  }

  .online {
    position: fixed;
    right: 0;
    top: 400px;
    width: 240px;
    height: 174px;
    z-index: 2;
    display: flex;
    flex-direction: column;

    .top {
      width: 100%;
      height: 72px;
      background-color: #00B074;
      font-size: 20px;
      color: #fff;
    }

    .bottom {
      flex: 1;
      background-color: #fff;
      font-size: 16px;
      padding: 22px 30px;
      box-sizing: border-box;

      .tel {
        font-size: 20px;
        margin-top: 14px;
      }
    }
  }
}

.swiper_img {
  height: 100%;
  width: 100%;
}

.el-menu-item {
  margin-right: 80px !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: solid 1px #00000000;
}

.bullet-active {
  background: #00ffaa !important;
  opacity: 1;
}

.el-backtop,
.el-calendar-table td.is-today {
  color: #00B074;
}

.dialog_content {
  text-align: center;

  .dialog_img {
    height: 200px;
    width: 200px;
  }
}

.enlarge-box {
  width: 100px;
  height: 100px;
  background-color: #ccc;
}

.enlarged {
  transform: scale(1.5);
  animation: enlarge 2s ease-in-out;
}

@keyframes enlarge {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.5);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
