import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import '@/assets/style/normalize.css'
import '@/assets/style/common.css'
import "swiper/css/swiper.css";
import './assets/fonts/iconfont.css';
import 'lib-flexible';
import 'aos/dist/aos.css'; // 引入 AOS 样式文件
import AOS from 'aos';
// import './rem.js';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(AOS.init({
  offset: 200, // 手动调整偏移量
  duration: 700, // 动画持续时间
  easing: 'ease-in-out', // 缓动函数
  once: false
}));
// 浏览器窗口,这个地方值不会变,你任意拉扯浏览器也不会改变,获取的是你打开项目时的初始状态
Vue.prototype.width = window.innerWidth;
Vue.prototype.height = window.innerHeight;
new Vue({
  render: h => h(App),
}).$mount('#app')
